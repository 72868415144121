<template>
    <header class="relative">
      <!-- Ícono de Home -->
      <router-link to="/" class="absolute top-4 left-4 flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8 text-white hover:text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 9.75L12 3l9 6.75v10.5A2.25 2.25 0 0118.75 21h-13.5A2.25 2.25 0 013 20.25V9.75z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 22V12h6v10"
          />
        </svg>
      </router-link>
  
      <!-- Imagen de portada a lo largo -->
      <img
        src="/images/banner.webp"
        alt="Portada"
        class="w-full h-48 object-cover"
        style="object-position: 60% 20%;"
      />
  
      <!-- Botón del carrito superpuesto -->
      <div class="absolute top-4 right-4">
        <button @click="toggleCart" class="relative">
          <img
            src="/images/cart-icon-white.png"
            alt="Carrito"
            class="h-8 w-8 object-contain"
          />
          <span
            v-if="cart.length > 0"
            class="absolute -top-2 -right-2 bg-red-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center"
          >
            {{ cart.length }}
          </span>
        </button>
      </div>
    </header>
  </template>
  
  <script>
  export default {
    name: "Header",
    props: {
      cart: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      toggleCart() {
        this.$emit("toggle-cart");
      },
    },
  };
  </script>
  
  <style scoped>
  header {
    position: relative;
  }
  
  button {
    cursor: pointer;
  }
  </style>
  